/** @format */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiTypography-root {
  font-family: 'Quicksand', sans-serif !important;
}

/* ================ SCROLL BAR CSS =============== */

::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(128, 126, 126, 0.411);
  border-radius: 99999px;
}

::-webkit-scrollbar-thumb {
  background: #46444446;
  border-radius: 99999px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #1f1f1f80;
  transition: linear 300ms;
}

/* 
.modal-video::-webkit-media-controls-mute-button,
.modal-video::-webkit-media-controls-volume-slider,
.modal-video::-webkit-media-controls-panel

 {
  display: none !important;
} */
/* .modal-video::-webkit-media-controls {
  overflow: hidden;
} */

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiline-row1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.res_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ================ CUSTOM SELECT OPTIONS ================== */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  border-radius: 12px;
  background-color: rgb(18 19 23);
}

.MuiFormControl-root .MuiBox-root svg {
  stroke: white;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline::after,
.MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
  content: none;
}

.MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
  background-color: transparent !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  display: none;
}

.MuiInputBase-root.MuiInput-root,
.MuiButtonBase-root.MuiMenuItem-root {
  font-family: 'Montserrat', sans-serif !important;
}

/* ================ CUSTOM SELECT CHANGING BACKGROUND================== */
.MuiList-root.MuiList-padding.MuiMenu-list {
  color: #ffffff;
}

/* ================ HOVER ICON================== */

:hover.hover-icon {
  background-color: #686666bd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
:hover.hover-icon-view {
  background-color: #686666bd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  width: 30px;
  height: 30px ;
  cursor: pointer;
}

:hover.hover-textMain {
 color: #ffffff !important;
}

/* ================ Divider ================== */
.divider {
  background-color: #CCCCCC;
  width: 80%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}